import { formatMetricValue, addGtmPropsToHighlightBannerComponent } from '../metrics/metrics-helpers';
import { Helpers } from '../core/src/helpers';

export const DEFAULT_PAGE_SIZE = 48;
export const PAGE_SIZE_LAZY = 6;
export const PAGE_SIZE_LAZY_MOBILE = 2;
const DEFAULT_FACET_ADDONS = {
    value: {
        addOnFacets: [
            {
                facetKey: 'priceFacet',
                facetSettings: [
                    {
                        description: 'Default default price range',
                        pathRegex: null,
                        enabled: true,
                        expanded: true,
                        displaySequence: 1,
                        value: [
                            { label: 'Under $600', value: '0-600' },
                            { label: '$600 to $1000', value: '600-1000' },
                            { label: '$1000 to $1500', value: '1000-1500' },
                            { label: '$1500 and above', value: '1500-99999' },
                        ],
                    },
                ],
            },
        ],
    },
};

/**
 * Loops through facet override component and finds picks the facet settings given the current vanityUrl and filter selections
 * @param {*} vanityUrl
 * @param {*} component
 * @param {*} filters
 * @returns
 */
export const getFacetAddons = Helpers.memoize(
    (vanityUrl, component, filters = {}) => {
        const { value } = component || DEFAULT_FACET_ADDONS;
        const { addOnFacets } = value || {};
        return (addOnFacets || []).reduce((r, facet) => {
            const { facetKey, facetSettings } = facet;
            if (facetSettings.length === 1) {
                r[facetKey] = facetSettings[0];
                //set key as property to match ETR API response
                r[facetKey].key = facetKey;
            } else {
                //find the first best match in the facet settings
                r[facetKey] = facetSettings.find(facetSetting => {
                    const { enabled, maxSubCats, minSubCats, pathRegex, subCats, subCatsExclude } = facetSetting || {};
                    const subCat = filters['subcat'];
                    const subCatCount = Object.keys(subCat || {}).length;
                    try {
                        return (
                            enabled &&
                            (typeof maxSubCats !== 'number' || subCatCount <= maxSubCats) &&
                            (typeof minSubCats !== 'number' || subCatCount >= minSubCats) &&
                            (!subCats || subCats.split(',').reduce((r, cat) => r && cat in subCat, true)) &&
                            (!subCatsExclude ||
                                subCatsExclude.split(',').reduce((r, cat) => r && !(cat in subCat), true)) &&
                            (!pathRegex || new RegExp(pathRegex).test(vanityUrl))
                        );
                    } catch (e) {
                        return false;
                    }
                });
                //set key as property to match ETR API response
                r[facetKey].key = facetKey;
            }

            //index map it so its easier to use in the component
            r[facetKey].valMap = r[facetKey]?.value?.reduce((a, c) => {
                a[c.label] = { ...c };
                return a;
            }, {});
            return r;
        }, {});
    },
    (vanityUrl, component, filters) => {
        return `${vanityUrl}-${component?.key}-${JSON.stringify(filters)}`;
    },
);

export const buildFilterFromUrl = (url, ignorePage = true) => {
    if (!url) return {};
    const facets = url.split(';');
    const filters = {};
    const delimeter = '=';
    facets.forEach(facet => {
        const [key, valueString] = facet.split(
            new RegExp(`(?:${delimeter}|${encodeURIComponent(delimeter)})(.*)`, 'ig'),
        );
        if ((ignorePage && key === 'page') || !key) return;
        const value = valueString?.split(',') || [];
        const values = {};
        value.forEach(val => {
            values[val] = val;
        });
        filters[key] = values;
    });
    return filters;
};

export const buildUrlFromFilters = filters => {
    const urlFacets = [];
    Object.keys(filters)
        .sort((a, b) => a.localeCompare(b))
        .forEach(facet => {
            if (typeof filters[facet] === 'object') {
                const keys = Object.keys(filters[facet]).sort();
                if (keys.length < 1) return;
                urlFacets.push(`${facet}=${keys.map(key => key).join(',')}`);
            } else if (filters[facet]) {
                urlFacets.push(`${facet}=${filters[facet]}`);
            }
        });
    return urlFacets.join(';');
};

/**
 * builds finder-results query params
 */
export const getQueryParams = (slug, updatedFilters, params) => {
    const updatedPath = buildUrlFromFilters({ ...updatedFilters });
    return {
        path: `vwa/${slug}${updatedPath ? `/${updatedPath}` : ''}`,
        ...params,
    };
};

export const getPageParam = path => {
    const pageParam = path?.match(/page=(\d+)/);
    return pageParam ? pageParam[1] : 1;
};

export const getPageUrl = (url, page, hasFilters, withQuery = true) => {
    const pageMatch = url?.path?.match(/page=(\d+)/);

    if (!url?.path && page === 1) {
        return `${url.pathname}${withQuery ? url?.query : ''}`;
    }

    const isMulti = url?.path?.includes(';page=');
    const isSingle = url?.path?.includes('page=');
    if (isMulti) {
        return `${url.pathname}/${url.path.replace(';page=' + pageMatch?.[1], page > 1 ? `${';page=' + page}` : '')}${
            withQuery ? url?.query : ''
        }`;
    } else if (isSingle) {
        return `${url.pathname}${page > 1 ? '/' : ''}${url.path.replace(
            'page=' + pageMatch?.[1],
            page > 1 ? `${'page=' + page}` : '',
        )}${withQuery ? url?.query : ''}`;
    } else {
        return `${url.pathname}/${url.path || ''}${page > 1 ? `${hasFilters > 0 ? ';' : ''}page=${page}` : ''}${
            withQuery ? url?.query : ''
        }`;
    }
};

// Adds GTM values to the VWA banner components (the banners may have the structure { highlightBanner: []} or [{...}])
export const getVwaBannerGtmWithDifferentStructures = ({ component, analyticsData, componentKey }) => {
    const hasHighlightBanner = component && component.highlightBanner;

    if (!component || (!hasHighlightBanner && (!Array.isArray(component) || !component[0]))) return component;

    try {
        if (hasHighlightBanner) {
            return addGtmPropsToHighlightBannerComponent({
                componentKey,
                component,
                analyticsData,
            });
        }

        let [banner] = component;
        let { highlightBanner } = addGtmPropsToHighlightBannerComponent({
            componentKey,
            component: { highlightBanner: banner },
            analyticsData,
        });

        component[0] = {
            ...(highlightBanner || {}),
            gtmActions: highlightBanner.gtmActions,
        };
    } catch (e) {}

    return component;
};

export const getVwaDynamicBannerComponent = component =>
    component && component.highlightBanner
        ? [component.highlightBanner]
        : Array.isArray(component) && component[0]
          ? [component[0]]
          : [];

export const getSearchKey = (path, orderBy) => `${path}${orderBy ? '-' + orderBy : ''}`;

export const sortFacets = ({ expandedFacets, facets }) => {
    const expandedOrder = expandedFacets.reduce((acc, expanded) => {
        const index = facets.findIndex(item => item.displayName.toLowerCase() === expanded.toLowerCase());
        const facet = facets[index];
        if (facet) {
            acc.push(facet);
            facets.splice(index, 1);
        }

        return acc;
    }, []);

    return [...expandedOrder, ...facets];
};

export const getInputProps = (expanded, placeholder, ariaLabel, preventFocusProp) => {
    return expanded
        ? {
              placeholder,
              'aria-label': ariaLabel,
          }
        : preventFocusProp;
};

export const resolveABTestData = (getABTestVWAProducts, products, filters) => {
    try {
        return typeof getABTestVWAProducts === 'function' ? getABTestVWAProducts(products, filters) : { products };
    } catch (e) {
        return { products };
    }
};
